import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import title_image from '../images/error_title_image.jpg'
import { Link } from "gatsby"

class NotFoundPage extends React.Component{
  render(){
    return(
      <Layout pageType="not-found">
      <SEO title="Page Not Found" />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
        <div className="overlay"></div>
          <div className="container">
              <h1 className="title">Error 404 <br /> Page not found</h1>
          </div>
        </section>
  
        <section className="section thank-you-page">
            <div className="container">
                  <p>Sorry, the page you are looking for does not exist. Try again or go back to</p>
                  <div className="button-area">
                    <Link to="/" className="button green">Homepage</Link>
                  </div>
            </div>
        </section>
    </Layout>
  
    )
  }
}

export default NotFoundPage